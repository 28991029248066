import * as React from "react"
import { useState, useEffect, useRef } from "react"
import Seo from "../components/seo"
import { IoMedalOutline } from "react-icons/io5"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"

const MysteryBoxGamePage = () => {
  const [rows, setRows] = useState([
    { boxNumber: 1, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 2, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 3, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 4, guess: "", finalAnswer: "", isGreen: false },
    { boxNumber: 5, guess: "", finalAnswer: "", isGreen: false },
  ])

  const [timeLeft, setTimeLeft] = useState(0)
  const [selectedTime, setSelectedTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [showRules, setShowRules] = useState(false) // State for rules modal
  const timerRef = useRef(null)
  const modalContentRef = useRef(null)

  const openModal = () => {
    setShowRules(true)
    // Scroll to top when the modal is opened
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    if (showRules && modalContentRef.current) {
      // Wait for the next tick to ensure the modal is fully painted
      setTimeout(() => {
        modalContentRef.current.scrollTop = 0
      }, 10) // Small delay, adjust if necessary
    }
  }, [showRules])

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows]
    newRows[index][field] = value
    setRows(newRows)
  }

  const handleButtonClick = index => {
    const newRows = [...rows]
    newRows[index].isGreen = !newRows[index].isGreen
    setRows(newRows)
  }

  const startTimer = () => {
    setIsRunning(true)
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    timerRef.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current)
          setIsRunning(false)
          return 0
        }
        return prev - 1
      })
    }, 1000)
  }

  const pauseTimer = () => {
    setIsRunning(false)
    clearInterval(timerRef.current)
  }

  const resetTimer = () => {
    setIsRunning(false)
    clearInterval(timerRef.current)
    setTimeLeft(0)
    setSelectedTime(0)
  }

  const handleTimeSelection = time => {
    setSelectedTime(time)
    setTimeLeft(time)
  }

  useEffect(() => {
    return () => clearInterval(timerRef.current)
  }, [])

  return (
    <div className="bg-white dark:bg-zinc-900 pt-1 pb-6 p-2">
      <Seo
        title={"Mystery Box Game"}
        description="A fun and engaging mystery box guessing game"
        defer={false}
      />
      <div className="container mx-auto flex mt-8">
        {/* Left Section: Mystery Box Game Table */}
        <section className="w-2/3 rounded text-black dark:text-white">
          <div className="">
            <img
              src="https://cdn.sanity.io/images/rbhjt84j/production/dc1bdfaeebce3bfddf745df7ca22bfa4c27de393-1457x623.png"
              className="w-full rounded-t-xl mb-0"
            />
            <table className="w-full table-auto rounded-b-xl border-collapse">
              <thead>
                <tr className="bg-[#45AEB7] dark:bg-zinc-800">
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Box Number
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Guess
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">
                    Final Answer
                  </th>
                  <th className="border  dark:border-gray-600 px-2 py-2">?</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    key={index}
                    className={`text-center ${
                      row.isGreen ? "bg-green-500" : ""
                    }`}
                  >
                    <td className="border  dark:border-gray-600 px-2 py-2 font-bold">
                      Box {row.boxNumber}
                    </td>
                    <td className="border dark:border-gray-500 px-2 py-2">
                      <input
                        type="text"
                        value={row.guess}
                        onChange={e =>
                          handleInputChange(index, "guess", e.target.value)
                        }
                        className="w-full p-1"
                      />
                    </td>
                    <td className="border  dark:border-gray-600 px-2 py-2">
                      <input
                        type="text"
                        value={row.finalAnswer}
                        onChange={e =>
                          handleInputChange(
                            index,
                            "finalAnswer",
                            e.target.value
                          )
                        }
                        className="w-full p-1"
                      />
                    </td>
                    <td className="border  dark:border-gray-600 px-2 py-2 ">
                      <button
                        onClick={() => handleButtonClick(index)}
                        className="dark:bg-zinc-600 bg-gray-300 text-white font-bold py-1 px-1 rounded"
                      >
                        <IoMedalOutline />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Right Section: Timer */}
        <section className="w-1/3 pl-4">
          <div className="bg-gray-200 dark:bg-zinc-800 rounded-lg p-4">
            <h2 className="text-xl text-center title font-bold dark:text-white mb-4">
              Countdown Timer
            </h2>
            <div className="mb-4">
              <p className="text-6xl font-bold text-center dark:text-white">{`${Math.floor(
                timeLeft / 60
              )
                .toString()
                .padStart(2, "0")}:${(timeLeft % 60)
                .toString()
                .padStart(2, "0")}`}</p>
            </div>
            <div className="flex flex-col items-center mb-4">
              <button
                onClick={startTimer}
                className="bg-[#3B8592] w-full hover:bg-[#3B8592]/80 text-white font-bold py-2 px-4 mb-2 rounded"
                disabled={isRunning || selectedTime === 0}
              >
                Start
              </button>
              <button
                onClick={pauseTimer}
                className="bg-yellow-500 w-full hover:bg-yellow-700 text-white font-bold py-2 px-4 mb-2 rounded"
              >
                Pause
              </button>
              <button
                onClick={resetTimer}
                className="bg-red-500 w-full hover:bg-red-700 text-white font-bold py-2 px-4 mb-4 rounded"
              >
                Reset
              </button>
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => handleTimeSelection(60)}
                className={`${
                  selectedTime === 60
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                1 min
              </button>
              <button
                onClick={() => handleTimeSelection(180)}
                className={`${
                  selectedTime === 180
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                3 min
              </button>
              <button
                onClick={() => handleTimeSelection(300)}
                className={`${
                  selectedTime === 300
                    ? "bg-gray-700"
                    : "bg-gray-500 hover:bg-gray-700"
                } text-white font-bold py-1 px-1 rounded`}
              >
                5 min
              </button>
            </div>
            <div className="text-center py-8 px-4 text-gray-400 dark:text-zinc-600">
              <h3 className="alkacaps text-xl ">
                A game of Inquiry and Intrigue
              </h3>
              <p className="text-sm">Tip: Choose your questions carefully.</p>
            </div>
          </div>
        </section>
      </div>

      {/* Floating Action Button for Rules */}
      <button
        onClick={() => setShowRules(true)}
        className="fixed bottom-4 left-4 bg-[#45AEB7] dark:bg-zinc-600 hover:bg-[#45AEB7]/50 text-white p-3 rounded-full shadow-lg"
      >
        📝 Rules
      </button>

      {/* Modal for Rules */}
      <Transition show={showRules} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          open={showRules}
          onClose={() => setShowRules(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left text-xs  bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <span className="alkacaps"> Mystery Box </span>Game Rules
                </Dialog.Title>
                <div className="flex text-xs bg-gray-100 pa-2 my-3">
                  <div className="">
                    <strong>Prepare the Boxes:</strong>

                    <p>
                      Prepare 3-5 mystery boxes. All items inside a single box
                      are the same, but the contents of each box differ from
                      each other.
                    </p>
                  </div>
                  <div className="ml-5" ref={modalContentRef}>
                    <strong>Form Groups:</strong>
                    <p>Break participants into groups of 3-5 players.</p>
                  </div>
                </div>
                <ol className="list-decimal ml-5 text-xs space-y-3 text-gray-800 dark:text-gray-200">
                  <li>
                    <strong>Initial Guess (3 Minutes):</strong>
                    <ul className="list-disc ml-5 space-y-1">
                      <li>
                        Give each group 3 minutes to guess what’s inside their
                        box.
                      </li>
                      <li>Input each group's guess into the chart.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Yes/No Questions Round 1 (5 Minutes):</strong>
                    <ul className="list-disc ml-5 space-y-1">
                      <li>
                        Give each group 5 minutes to come up with 3 yes or no
                        questions.
                      </li>
                      <li>
                        Before the timer starts, explain to them: "Facilitators
                        may answer differently. If they do, it means your
                        question is subjective. Keep that in mind."
                      </li>
                      <li>
                        After 5 minutes, have each group ask their questions in
                        front of everyone. After all groups have recieved their
                        answers, move to round 2.
                      </li>
                    </ul>
                  </li>

                  <div className="bg-yellow-200">
                    <strong>Contextual Thinking:</strong>
                    <p>
                      Before round 2, guide the groups to think about contextual
                      questions, such as: Where might I find this object? How is
                      this object used?
                    </p>
                  </div>

                  <li>
                    <strong>Yes/No Questions Round 2:</strong>
                    <ul className="list-disc ml-5 space-y-1">
                      <li>Let the groups ask their 3 yes/no questions.</li>
                      <li>
                        Prompt them: "What can’t we observe by shaking, moving,
                        or sniffing the box?"
                      </li>
                    </ul>
                  </li>

                  <div className="bg-yellow-200">
                    <strong>Watch Out for Assumptions:</strong>
                    <p>
                      Warn the groups about making assumptions. Provide an
                      example: If they think the box contains crayons and ask
                      "Is it made of wax?" and the answer is no, they might get
                      stuck with the wrong idea.
                    </p>
                  </div>

                  <li>
                    <strong>Final Guess:</strong>
                    <ul className="list-disc ml-5 space-y-1">
                      <li>
                        After the final round of questions, give each group time
                        to make their final guess.
                      </li>
                      <li>Input the final guesses into the chart.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Reveal the Answers:</strong>
                    <ul className="list-disc ml-5 space-y-1">
                      <li>
                        Once all groups have guessed, open the boxes and reveal
                        the contents.
                      </li>
                      <li>
                        Mark correct guesses in the chart by clicking the
                        "medal" button.
                      </li>
                    </ul>
                  </li>
                </ol>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => setShowRules(false)}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default MysteryBoxGamePage
